.convocatorias {
  background-color: #F8FAFC;
  padding: 5rem;
}

.convocatorias h2 {
  text-align: center;
  font-weight: 700;
  margin-bottom: 5rem;
}

.convocatorias-grid {
  display: none;
}

.convocatorias-grid img {
  width: 100%;
  height: auto;
  max-width: 250px; 
  display: block;
  margin: 0 auto;
  border-radius: 1rem
}

.carousel {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.carousel-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

.convocatoria {
  margin: 0;
}

.convocatoria img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 1rem
  /*max-width: 100%;
  width: 100%;
  height: 270px; 
  object-fit: contain;*/
}

.indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.indicator.active {
  background-color: #B11830;
}

@media (min-width: 768px) and (max-width: 1024px){
  .convocatorias-grid {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 20px;
  }

  .carousel{
    display: none;
  }

  .convocatoria img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .convocatorias {
    padding: 5rem;
  }
}

@media (min-width: 1024px){
  .convocatorias-grid {
    display: grid;
    grid-template-columns: repeat(4, 3fr);
    gap: 30px;
  }

  .carousel{
    display: none;
  }

  .convocatoria img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .convocatorias {
    padding: 7rem;
  }
  
}

/*.convocatorias {
    text-align: center;
    padding: 20px;
  }
  
  .convocatorias h2 {
    font-size: 30px;
    font-weight: 700;
    margin-top: 5rem;
    margin-bottom: 3rem;
  }
  .convocatorias-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 20px;
    justify-items: center;
  }
  
  .convocatorias-grid img {
    width: 100%;
    max-width: 250px;
    border-radius: 8px;
    object-fit: cover;
  }


  
/* diseño de las convocatorias 

  .convocatoria {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    max-width: 250px; /* Ajusta el ancho máximo según tu diseño 
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .convocatoria-image {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
  
  .convocatoria-title {
    font-size: 18px;
    margin: 10px ;
  }

  @media (max-width: 1440px) {
    .convocatorias-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 803px)  {
    .convocatorias-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 393px) {
  .convocatorias-grid {
    display: none;
  }

  .carousel {
    position: relative;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .carousel-content {
    display: flex;
    justify-content: center;
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }

  .arrow.left {
    left: 0;
  }

  .arrow.right {
    right: 0;
  }

  .convocatoria {
    margin: 0;
  }

  .indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .indicator.active {
    background-color: #B11830;
  }
}*/