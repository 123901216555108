.documentos{
    display: flex;
    flex-direction: column;
    height: 100vh;
}


.documentos h1{
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 2rem;
    text-align: center;
}

.archivos{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.archivos a{
    text-decoration: none;
    padding: 15px;
    color: black;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.archivos img {
    width: 28px;
    height: 28px;
    margin-right: 10px;
}