
/*footer.css */
.footer {
  background-color: #333;
  color: #ADB5BD;
  padding: 20px 0;
  text-align: justify;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0 20px;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  padding: 10px;
}

.footer-section h3 {
  font-size: 16px;
  color: #fff;
}

.footer-section p,
.footer-section a{
  font-size: 14px;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}

.footer-bottom {
  background-color: #b11830;
  color: #fff;
  padding: 15px 0;
}

.footer-logo {
  height: 55px;
}

.footer-logo img {
  max-width: 150px; 
  height: auto;
}

.social-icons {
  display: flex;
  gap: 10px;
  justify-content:first baseline;
}

.social-icons a {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

.social-icons i{
  font-size: 1.3rem;
  color: #ADB5BD;
}

.contact-icons a{
  color: #ADB5BD;
}

.contact-icons i{
  font-size: 1rem;
  color: #fff;
  padding: 0.5rem;
}

.verMapa a{
  color: #3399CC;
}

.direccion p{
  color: #ADB5BD;
}

.logoImage {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

@media (max-width: 1440px) {
  
  .footer-section h3 {
    font-size: 16px;
  }

  .footer-section p,
  .footer-section a {
    font-size: 12px;
  }
}

@media (max-width: 834px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    text-align: center;
    padding: 10px 0;
    width: 100%;
  }
  .footer-logo {
    height: 30px;
  }

  .footer .logo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .social-icons a {
    font-size: 14px;
  }
}

@media (max-width: 393px) {
  .footer-logo {
    height: 30px;
  }

  .social-icons a {
    font-size: 18px;
  }
}