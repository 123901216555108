/* pasos.css*/
.pasos{
  margin-top: 8rem;
  background-image: url('../../assets/image/fondo-movilidad.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.pasos h2{
  text-align: center;
  font-weight: 700;
  margin-bottom: 6rem;
}

span{
  font-weight: bold;
}

a{
  text-decoration: none;
}

.pasos-grid{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  padding: 1rem;
}

.pasos-grid p{
  
}

.pasos-grid h3{
  color: #EF4C4C;
  font-weight: 700;
}

.paso-item.right  {
  padding-right: 70px;
}

.paso-item.left {
  padding-left: 70px;
}

.paso-item .numero {
  font-size: 24px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .pasos-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .pasos-grid p{
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .pasos-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
  }

  .pasos-grid h3{
    font-size: 30px;
  }

  .paso-item.right  {
    padding-right: 0;
  }
  
  .paso-item.left {
    padding-left: 0;
  }
}
/*.pasos {
  padding: 20px;
  background: #fff;
}

.pasos h2 {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 3rem;
  font-size: 24px;
  font-weight: 700;
}

.pasos-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.paso-item {
  background: #fff;
  padding: 70px;
  text-align: left;
}

.paso-item h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.paso-item .numero {
  color: rgba(239, 76, 76, 1);
  font-size: 30px;
  font-weight: bold;
}

.paso-item p {
  font-size: 16px;
  color: #555;
}

.paso-item a {
  color: #E91E63;
  text-decoration: none;
  font-weight: bold;
}

.paso-item a:hover {
  text-decoration: underline;
}

@media (max-width: 1440px) {
  .pasos-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 834px) {
  .pasos-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 393px) {
  .pasos-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .paso-item {
    padding: 20px;
    
  }

  .paso-item.right  {
    padding-right: 70px;
  }

  .paso-item.left {
    padding-left: 70px;
  }

  .paso-item .numero {
    font-size: 24px;
  }
}*/