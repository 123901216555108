.main{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.destinos{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.destinos p{
    max-width: 600px;
    margin: 0 auto;
    font-size: 1rem;
}

.destinos h1{
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 2rem;
}

.select{
    margin-top: 30px;
    padding: 15px;
}

.form-select{
    font-size: 16px;
}

.info-destinos{
    margin-top: 1rem;
    width: 100%;
}

.info-destinos-content {
    text-align: left;
    margin: 0 auto;
    max-width: 1200px;
}

.info-destinos-content span{
    font-weight: bold;
}


.universidades {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.universidades h3{
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.universidad {
    display: flex;
    align-items: center;
}

.universidad .imgPais{
    width: 28px;
    height: 28px;
    margin-bottom: 1rem;
    margin-right: 1rem;
}

.universidad p {
    margin: 0;
    background-color: #EBF5FA;
    padding: 4px;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
.universidad a {
    text-decoration: none;
    margin-left: 1rem;
    color: black;
    margin-bottom: 1rem;
}



.carrera-titulo{
    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom: 2rem;
}

@media (max-width: 393px){
    .main{
        padding: 1rem;
    }
}